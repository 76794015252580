import { useMemo, useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useLocalstorageState } from 'rooks';

import { useAuth } from 'context/Auth';
import UserReaction from './UserReaction';
import { Reactions } from './FundraisingUpdate';

const CREATE_OR_UPDATE_REACTION = gql`
  mutation CreateOrUpdateReaction(
    $id: String!
    $reaction: ReactionreactionTypeEnumType
    $token: String
  ) {
    setReaction(
      subjectType: FundraisingUpdate
      subjectId: $id
      reactionType: $reaction
      token: $token
    ) {
      token
    }
  }
`;

interface FundraisingUpdateUserReactionProps extends Reactions {
  id: string;
  display?: 'counts' | 'button-sm' | 'button-lg';
}

const FundraisingUpdateUserReaction = ({
  id,
  myReaction = null,
  likeCounter = null,
  loveCounter = null,
  clapCounter = null,
  cryCounter = null,
  yayCounter = null,
  hahaCounter = null,
  wowCounter = null,
  display = 'button-lg',
  ...props
}: FundraisingUpdateUserReactionProps) => {
  const { isLoggedIn } = useAuth();
  const [localReactions, setLocalReactions] = useLocalstorageState('pledgeit:reactions', {});

  const localReaction = useMemo(
    () => (isLoggedIn ? null : localReactions[`FundraisingUpdate:${id}`]),
    [isLoggedIn, localReactions, id]
  );

  const currentReaction = useMemo(
    () => (isLoggedIn ? myReaction : localReaction?.reaction) ?? null,
    [isLoggedIn, myReaction, localReaction]
  );

  const [createOrUpdateReaction] = useMutation(CREATE_OR_UPDATE_REACTION, {
    variables: { id, token: localReaction?.token },
  });

  const onReact = useCallback(
    (reaction) => {
      createOrUpdateReaction({
        variables: { reaction },
        update: (cache, { data: response }) => {
          const getUpdatedCount = (x) => {
            const currentCount =
              {
                like: likeCounter,
                love: loveCounter,
                clap: clapCounter,
                cry: cryCounter,
                yay: yayCounter,
                haha: hahaCounter,
                wow: wowCounter,
              }[x] ?? 0;

            if (x === currentReaction) return Math.max(currentCount - 1, 0);
            if (x === reaction) return currentCount + 1;
            return currentCount;
          };

          // Update the cache, including myReaction if logged in
          cache.writeFragment({
            id: `FundraisingUpdate:${id}`,
            fragment: gql`
              fragment FundraisingUpdateReactions on FundraisingUpdate {
                myReaction
                likeCounter
                loveCounter
                clapCounter
                cryCounter
                yayCounter
                hahaCounter
                wowCounter
              }
            `,
            data: {
              myReaction: reaction,
              likeCounter: getUpdatedCount('like'),
              loveCounter: getUpdatedCount('love'),
              clapCounter: getUpdatedCount('clap'),
              cryCounter: getUpdatedCount('cry'),
              yayCounter: getUpdatedCount('yay'),
              hahaCounter: getUpdatedCount('haha'),
              wowCounter: getUpdatedCount('wow'),
            },
          });

          // Otherwise, update the reaction map in local storage
          if (!isLoggedIn) {
            setLocalReactions({
              ...localReactions,
              [`FundraisingUpdate:${id}`]: reaction
                ? { token: response.setReaction.token, reaction }
                : null,
            });
          }
        },
      });
    },
    [
      id,
      createOrUpdateReaction,
      currentReaction,
      isLoggedIn,
      setLocalReactions,
      localReactions,
      likeCounter,
      loveCounter,
      clapCounter,
      cryCounter,
      yayCounter,
      hahaCounter,
      wowCounter,
    ]
  );

  return (
    <UserReaction
      value={currentReaction}
      onChange={onReact}
      likeCounter={likeCounter}
      loveCounter={loveCounter}
      clapCounter={clapCounter}
      cryCounter={cryCounter}
      yayCounter={yayCounter}
      hahaCounter={hahaCounter}
      wowCounter={wowCounter}
      display={display}
      {...props}
    />
  );
};

export default FundraisingUpdateUserReaction;
